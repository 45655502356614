// Sidebar.js
import React from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useState } from 'react';
interface SidebarProps {
  setRecruitmentOpen: (value: boolean) => void;
  isRecruitmentOpen: boolean;
  setSalesOpen: (value: boolean) => void;
  isSalesOpen: boolean;
  setNewGraduateOpen: (value: boolean) => void;
  isNewGraduateOpen: boolean;
  setSelectedContent: (value: string) => void;
}

const Sidebar = ({ setSelectedContent } : { setSelectedContent: (value: string) => void }) => {
  const handleSignOut = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      window.location.reload();
    } catch (error) {
      console.error("ログアウト中にエラーが発生しました: ", error);
    }
  };

  const menuItems = [
    /*
    {
      category: '採用',
      subCategories: [
        {
          name: '新卒採用',
          contents: [
            { name: 'フィードバック文章生成', onClick: () => setSelectedContent('フィードバック文章生成') },
          ],
        },
        {
          name: 'キャリア/中途採用',
          contents: [
            { name: 'フィードバック文章生成', onClick: () => setSelectedContent('フィードバック文章生成') }
          ],
        },
      ],
    },
    */
    {
      category: '営業',
      subCategories: [
        {
          name: '営業管理',
          contents: [
            { name: '商談前：討議準備', onClick: () => setSelectedContent('商談前：討議準備') },
            { name: '商談後：解決方法アイディア', onClick: () => setSelectedContent('商談後：解決方法アイディア') },
            { name: '商談後：骨子作成', onClick: () => setSelectedContent('商談後：骨子作成') },
          ],
        },
      ],
    },
  ];
  const [selectedContentIndex, setSelectedContentIndex] = useState<number | null>(-1);

  return (
    <div className="bg-menu-primary min-h-full w-60 relative">
      <ul>
        {menuItems.map((item, index) => (
          <li key={index}>
            <div className="text-major-category p-4 border-b border-border-menu">{item.category}</div>
            <ul>
              {item.subCategories.map((subCategory, subIndex) => (
                <li key={subIndex}>
                  <div className="text-major-category text-xl mt-6 pl-4">{subCategory.name}</div>
                  {subCategory.contents.length > 0 && (
                    <ul className="mt-5">
                      <li className="mt-4">
                        <div className="text-middle-category text-xs ml-4 mb-1">フィードバック</div>
                        <ul className="text-minor-category">
                          {subCategory.contents.map((content, contentIndex) => (
                            //change background color of content which is selected
                            //change text color of content which is selected
                            /*
                            <li
                              key={contentIndex}
                              className={`w-fit ml-2 p-2 text-base rounded ${
                                contentIndex == 1 ? 'bg-menu-active text-content-primary' : 'hover:bg-menu-active hover:text-content-primary'
                              }`}
                            >
                              <a href="#" onClick={() => {
                                content.onClick
                                //setSelectedContentIndex(contentIndex)
                              }}>{content.name}</a>
                            </li>
                            */
                           //make text white
                           
                          
                           
                            
                            <li
                              key={contentIndex}
                              className={`w-fit ml-2 p-2 text-base rounded hover:bg-menu-active hover:text-content-primary ${
                                contentIndex == selectedContentIndex ? 'text-white' : 'text-white'
                              }`}
                            >
                              <a href="#" onClick={() => {
                                content.onClick()
                                setSelectedContentIndex(contentIndex)
                              }}
                              className='text-white'>{content.name}</a>
                            </li>
                            
                          ))}
                        </ul>
                      </li>
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
      <a className="text-major-category absolute bottom-0 p-4" href="#" onClick={handleSignOut}>ログアウト</a>
    </div>
  );
}

export default Sidebar;