import React, { useState, useEffect } from 'react';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, GoogleAuthProvider, signInWithPopup, signOut as firebaseSignOut } from 'firebase/auth';

import './Auth.css';

function Auth({ onLoginSuccess }: any ) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(true);  // isLoading stateの追加

    const auth = getAuth();
    const buttonStyle = {
        display: 'inline-block',
        height: '48px',
        border: '1px solid #ccc', // 枠を追加
        borderRadius: '4px', // 角を丸める
        width: '300px', // 横幅を300pxに設定
        background: 'none',
        padding: 0,
        margin: 0,
        cursor: 'pointer',
      };
    
      const iconStyle = {
        width: '24px',
        height: '24px',
        verticalAlign: 'middle', // アイコンを垂直方向に中央揃え
        //marginRight: '12px', // アイコンとテキストの間の余白を設定
        marginLeft: '12px',
      };
    
      const textStyle = {
        verticalAlign: 'middle',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
    };
    

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, user => {
            if (user) {
                onLoginSuccess();
            }
            setIsLoading(false);  // 認証状態の確認が完了したらisLoadingをfalseに設定
        });
        
        return () => unsubscribe();
    }, [auth, onLoginSuccess]);

    const signIn = async () => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
            onLoginSuccess();
        } catch (error) {
            alert("IDもしくはパスワードが間違えています");
        }
    };

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            signIn();
        }
    }

    const signInWithGoogle = async () => {
        const provider = new GoogleAuthProvider();

        try {
            await signInWithPopup(auth, provider);
        } catch (error) {
            console.error('Googleサインインエラー:', error);
        }
    };

    if (isLoading) {  // isLoadingがtrueの場合、ローディングインジケータを表示
        return <div>Loading...</div>;
    }

    return (
        <div className="auth-container">
            <div className="auth-form">
                <input
                    className="auth-input"
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
                <input
                    className="auth-input"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
                <button className="auth-button" onClick={signIn}>Sign In</button>
                <div className="divider-block">
                <span className="divider"></span>
                <span className="divider-text"> または </span>
                <span className="divider"></span>  
                </div>
                
                
                
                <button className="gsi-material-button" onClick={signInWithGoogle} style={buttonStyle}>
      <div className="gsi-material-button-state"></div>
      <div className="gsi-material-button-content-wrapper">
        <div className="gsi-material-button-icon">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlnsXlink="http://www.w3.org/1999/xlink" style={iconStyle}>
            <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
            <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
            <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
            <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
            <path fill="none" d="M0 0h48v48H0z"></path>
          </svg>
        </div>
        <span className="gsi-material-button-contents" style={textStyle}>Googleで続ける</span>
      </div>
    </button>
                
            </div>
        </div>
    );
}

export default Auth;