import axios from 'axios';
import { toast } from "react-toastify";


const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://abetone-72ilxxakpq-an.a.run.app/' : 'http://localhost:8080/';

export const postToEndpoint = async (endpoint: string, requestBody: any) => {
    try {
        const response = await axios.post(BASE_URL + endpoint, requestBody, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        if (response.data.error) {
            throw new Error(response.data.error);
        }

        return response.data.response;
    } catch (error) {
        toast.error("エラーが発生しました。もう一度お試しください。");
    }
};


export const postToEndpointWithStream = async (endpoint: string, requestBody: any) => {
    try {
        const response = await fetch(BASE_URL + endpoint, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                "Content-Type": "application/json"
            },
        });
        if (response.body === null) {
            throw new Error(response.ok.toString());
        }
        console.log("response", response);
        return response;
    } catch (error) {
        console.error("エラーが発生しました。もう一度お試しください。", error);
        toast.error("エラーが発生しました。もう一度お試しください。");
    }
}