import React, { useState } from 'react';
import Auth from './Auth';
import MainContent from './MainContent';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const App = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const handleLoginSuccess = () => {
        setIsLoggedIn(true);
    };

    if (!isLoggedIn) {
        return <Auth onLoginSuccess={handleLoginSuccess} />;
    }

    return <>
        <ToastContainer />
        <MainContent />
    </>
}
