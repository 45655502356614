import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDNQPExxM_66nLEgsFwcpIHDjMxKEwUo2g",
    authDomain: "cto-sandbox-8c790.firebaseapp.com",
    projectId: "cto-sandbox-8c790",
    storageBucket: "cto-sandbox-8c790.appspot.com",
    messagingSenderId: "93220749058",
    appId: "1:93220749058:web:717069bbfd0f297a7acc1b"
  };

initializeApp(firebaseConfig);
const auth = getAuth();

export { auth };
