import React, { useState } from 'react';
import Sidebar from './Sidebar';
import { postToEndpoint, postToEndpointWithStream } from './helpers/api';
import { useCallback } from 'react';
import ReactMarkdown from 'react-markdown';

interface ITextGeneratorProps {
  endpointName: string;
  inputFields: {
    id: string; 
    label: string; 
    type: string; 
    options: string[]; 
    impressionOptions: string[] 
  }[];
}


type StreamMessage = {
  threadId: number
  text: string
  error?: string
}

type RequestParams = {
  model: string;
  messages: { role: "user" | "system" | "assistant"; content: string }[];
};


function TextGenerator({ endpointName, inputFields }: ITextGeneratorProps) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [generatedText, setGeneratedText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [copyButtonText, setCopyButtonText] = useState("テキストをコピー");
  const [resMessage, setResMessage] = useState<StreamMessage | null>(null)
  type InputValuesType = Record<string, string>;
  const [inputValues, setInputValues] = useState<InputValuesType>(
    inputFields.reduce((acc, field) => (
      { ...acc, [field.id]: field.type === "select" ? field.options[0] : "" }
    ), {})
  );
  const [selectedImpressions, setSelectedImpressions] = useState<string[]>([]);
  const [selectedThread, setSelectedThread] = useState<any>();
  const copyToClipboard = () => {
    const textarea = document.createElement('textarea');
    textarea.value = generatedText;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    setCopyButtonText("コピーされました");
    setTimeout(() => {
      setCopyButtonText("テキストをコピー");
    }, 1000);
  };
  

  const generateText = async () => {
    const requestBody = {
      ...inputValues,
      ...(endpointName === 'feedbackGenerator' && { impressions: selectedImpressions })
    };

    setIsButtonDisabled(true);
    setIsLoading(true);

    try {
      
      //const generatedText = await postToEndpoint(endpointName, requestBody);
      for await (const message of streamChatCompletion(endpointName, requestBody)) {
        console.log("Received:", message);
      }
    
    } catch (error) {
      console.error("文章の生成中にエラーが発生しました：", error);
    } finally {
      setIsLoading(false);
      setIsButtonDisabled(false);
    }
  };

  const handleImpressionChange = (event: any) => {
    const value = event.target.value as string;
    if (!selectedImpressions.includes(value)) {
      setSelectedImpressions([...selectedImpressions, value]);
    }
  };

  const removeImpression = (impression: any) => {
    setSelectedImpressions(selectedImpressions.filter(item => item !== impression));
  };


  const previewStyle = {
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9',
    overflowY: 'auto' as const,
  };

  async function* streamChatCompletion(endpointName: string, requestBody: any) {
    const completion = await postToEndpointWithStream(endpointName, requestBody);
    console.log("Completion:", completion);
    console.log("=-------")
    const reader = completion?.body?.getReader();
  
    if (completion?.status !== 200 || !reader) {
      throw new Error("Request failed");
    }
  
    const decoder = new TextDecoder("utf-8");
    let done = false;
    let completeText = "";
    try {
    while (!done) {
      const { done: readDone, value } = await reader.read();
      if (readDone) {
        done = readDone;
        console.log("どね！")
        reader.releaseLock();
      } else {
        console.log("途中");
        const textChunk = decoder.decode(value, { stream: true });
        completeText += textChunk;
        setGeneratedText(completeText);
      }
    }
  } catch (error) {
    reader.releaseLock();
  }
  }

  return (
    <div className="flex p-10 min-h-full">
      <div className="flex-none w-80">
        {inputFields.map((field) => (
          <div key={field.id} className="mt-8">
            <label className="text-content-label text-sm after:pr-2" htmlFor={field.id}>
              {field.label}:
            </label>
            {field.type === "textarea" ? (
              <textarea
                className="p-4 rounded text-base w-full border-input border after:pr-2"
                id={field.id}
                value={inputValues[field.id]}
                onChange={(e) =>
                  setInputValues({ ...inputValues, [field.id]: e.target.value })
                }
              ></textarea>
            ) : field.type === "select" ? (
              <>
                <select
                  className="p-4 rounded text-base w-full border-input border after:pr-2"
                  id={field.id}
                  value={inputValues[field.id]}
                  onChange={(e) =>
                    setInputValues({ ...inputValues, [field.id]: e.target.value })
                  }
                >
                  {field.options.map((option) => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
                {field.id === "industryUnderstanding" && (
                  <>
                    <select className="mt-2 p-4 rounded text-base w-full border-input border" onChange={handleImpressionChange}>
                      {field.impressionOptions.map((option) => (
                        <option key={option} value={option}>{option}</option>
                      ))}
                    </select>
                    <div className="mt-2 flex flex-wrap">
                      {selectedImpressions.map((impression, index) => (
                        <div key={index} className="flex items-center m-1 border p-1 rounded">
                          <span className="text-sm mr-2">{impression}</span>
                          <button onClick={() => removeImpression(impression)} className="text-xs text-red-500">×</button>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </>
            ) : (
              <input
                className="p-4 rounded text-base w-full border-input border after:pr-2"
                id={field.id}
                value={inputValues[field.id]}
                onChange={(e) =>
                  setInputValues({ ...inputValues, [field.id]: e.target.value })
                }
              />
            )}
          </div>
        ))}

        <button
          className="bg-content-primary text-major-category w-full p-3 rounded mt-8 text-lg"
          onClick={generateText}
          disabled={isLoading}
        >
          {isLoading ? "文章を生成中..." : "文章を生成する"}
        </button>
      </div>

      <div className="ml-10 flex-auto relative">
        <textarea
          className="p-6 rounded w-full border-input border resize-non h-full"
          placeholder="生成された文章がここに表示されます"
          value={generatedText}
          onChange={(e) => setGeneratedText(e.target.value)}
        ></textarea>
        <button
          className="absolute bottom-3 right-3 bg-copy-button py-2 px-3 rounded text-xs"
          onClick={copyToClipboard}
        >
          {copyButtonText}
        </button>
      </div>
    </div>
  );
}

function MainContent() {
  const [isRecruitmentOpen, setRecruitmentOpen] = useState(false);
  const [isSalesOpen, setSalesOpen] = useState(false);
  const [isNewGraduateOpen, setNewGraduateOpen] = useState(false);
  const [selectedContent, setSelectedContent] = useState<string | null>(null);

  const getMainContent = (selected: string) => {
    if (selected === '商談前：討議準備') {
      const inputFields = [
        { id: "industry", label: "業界", type: "input", options: [], impressionOptions: []},
        { id: "organization", label: "部門", type: "input", options: [], impressionOptions: []},
      ];
      return <TextGenerator endpointName="discussionPreparation" inputFields={inputFields} />;
    }
    if (selected === '商談後：解決方法アイディア') {
      const inputFields = [
        { id: "industry", label: "業界", type: "input", options: [], impressionOptions: []},
        { id: "organization", label: "部門", type: "input", options: [], impressionOptions: []},
        { id: "customerIssues", label: "顧客の課題・実現したいこと", type: "textarea", options: [], impressionOptions: []},
      ];
      return <TextGenerator endpointName="solutionIdea" inputFields={inputFields} />;
    }
    if (selected === '商談後：骨子作成') {
      const inputFields = [
        { id: "customerIssues", label: "顧客の課題・実現したいこと", type: "textarea", options: [], impressionOptions: []},
        { id: "solutionIdea", label: "解決方法のアイディア", type: "textarea", options: [], impressionOptions: []},
      ];
      return <TextGenerator endpointName="draftProposal" inputFields={inputFields} />;
    }
    return <div className="text-base p-10">{selected}のコンテンツが表示されます。</div>;
  }

  return (
    <div className="App flex h-screen">
      <Sidebar
        setSelectedContent={setSelectedContent}
        /*
        setRecruitmentOpen={setRecruitmentOpen}
        isRecruitmentOpen={isRecruitmentOpen}
        setSalesOpen={setSalesOpen}
        isSalesOpen={isSalesOpen}
        setNewGraduateOpen={setNewGraduateOpen}
        isNewGraduateOpen={isNewGraduateOpen}
        setSelectedContent={setSelectedContent}
        */
      />

      {selectedContent && (
        <div className="flex-auto">
          <div className="flex flex-col h-screen">
            <h2 className="text-xl py-4 px-10 border-b border-input">{selectedContent}</h2>
            <div className="grow">{getMainContent(selectedContent)}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MainContent;